var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fill-height":"","fluid":""}},[(!_vm.checkPermision())?_c('v-container',{staticClass:"brand",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('img',{attrs:{"src":`${_vm.publicPath}/img/WSU_Shield_White.svg`,"width":"400"}})]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(
            !_vm.$store.getters.isLoggedIn &&
            Object.keys(_vm.$store.getters.staffMember).length > 8 &&
            !_vm.$store.getters.onPremise
          )?_c('v-btn',{class:{
              'white--text': true,
              'body-2': _vm.$vuetify.breakpoint.smAndDown,
            },attrs:{"type":"button","color":"#903","x-large":""},on:{"click":_vm.loginGetUser}},[_vm._v(" Login to view staff details ")]):_vm._e(),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":48,"width":4,"color":"light-blue"}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.checkPermision())?_c('div',{staticClass:"profile-container"},[_c('div',{staticClass:"closeModal"},[_c('v-btn',{attrs:{"icon":"","color":"#903"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(!_vm.hide)?_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"#903"},on:{"click":function($event){return _vm.copy(
                  `https://directory.westernsydney.edu.au/search/profile/${_vm.$store.getters.staffMember['ID']}`
                )}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,4161267260)},[_c('span',[_vm._v("Copy Permalink")])]),(
            _vm.$store.getters.isLoggedIn &&
            Object.keys(_vm.$store.getters.staffMember).length > 8 &&
            !_vm.$store.getters.onPremise
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":_vm.$vuetify.breakpoint.mdAndUp,"icon":_vm.$vuetify.breakpoint.smAndDown,"color":"#903"},on:{"click":_vm.loginGetUser}},on),[_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("mdi-dots-horizontal")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? "More Details" : "")+" ")],1)]}}],null,false,3678542700)},[_c('span',[_vm._v("Login to view staff details")])]):_vm._e(),(!_vm.isEmpty(_vm.getProfile()))?_c('v-btn',{attrs:{"text":!_vm.$vuetify.breakpoint.smAndDown,"icon":_vm.$vuetify.breakpoint.smAndDown,"color":"#903","href":`${_vm.getProfile()}`,"target":"_blank"}},[_c('v-icon',{attrs:{"left":!_vm.$vuetify.breakpoint.smAndDown}},[_vm._v("mdi-face")]),(!_vm.$vuetify.breakpoint.smAndDown)?_c('span',[_vm._v("View Profile")]):_vm._e()],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"profile"},[_c('div',{staticClass:"important-contact"},[_c('h1',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.staffName()))]),_c('h4',[_vm._v(_vm._s(this.$store.getters.staffMember["Position"]))]),_c('br'),_c('v-divider',{staticClass:"mx-4"}),_c('br'),_c('v-container',{class:{ 'mx-auto': true, 'pa-0': _vm.$vuetify.breakpoint.smAndDown }},[(this.$store.getters.staffMember['School Unit Name'])?_c('v-row',{staticClass:"text-center mb-1",attrs:{"dense":"","no-gutters":""}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{class:{
                  'mr-24': true,
                  'text-right': _vm.$vuetify.breakpoint.smAndUp,
                  'body-2': _vm.$vuetify.breakpoint.smAndDown,
                },attrs:{"cols":"12","sm":"4"}},[_c('strong',[_vm._v("Department:")])]),_c('v-col',{class:{ 'body-2': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","sm":"8"}},[(!_vm.hide)?_c('a',{attrs:{"href":`/schools/${this.$store.getters.staffMember['School Unit Code']}/staff`,"target":"_blank"}},[_vm._v(" "+_vm._s(this.$store.getters.staffMember["School Unit Name"])+" "),_c('v-icon',{attrs:{"color":"#903","x-small":""}},[_vm._v(" mdi-open-in-new ")])],1):_vm._e()])],1):_vm._e(),(this.$store.getters.staffMember['Unit Name'])?_c('v-row',{staticClass:"text-center mb-1",attrs:{"dense":"","no-gutters":""}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{class:{
                  'mr-24': true,
                  'text-right': _vm.$vuetify.breakpoint.smAndUp,
                  'body-2': _vm.$vuetify.breakpoint.smAndDown,
                },attrs:{"cols":"12","sm":"4"}},[_c('strong',[_vm._v("School or Division:")])]),_c('v-col',{class:{ 'body-2': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","sm":"8"}},[(!_vm.loading && !_vm.hide)?_c('a',{attrs:{"href":`/schools/${this.$store.getters.staffMember['Department Code']}/staff`,"target":"_blank"}},[_vm._v(" "+_vm._s(this.$store.getters.staffMember["Unit Name"])+" "),_c('v-icon',{attrs:{"color":"#903","x-small":""}},[_vm._v(" mdi-open-in-new ")])],1):_vm._e()])],1):_vm._e(),(this.$store.getters.staffMember['Campus Name'])?_c('v-row',{staticClass:"text-center mb-1",attrs:{"dense":"","no-gutters":""}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{class:{
                  'mr-24': true,
                  'text-right': _vm.$vuetify.breakpoint.smAndUp,
                  'body-2': _vm.$vuetify.breakpoint.smAndDown,
                },attrs:{"cols":"12","sm":"4"}},[_c('strong',[_vm._v("Campus:")])]),_c('v-col',{class:{ 'body-2': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","sm":"8"}},[_c('h4',[_vm._v(" "+_vm._s(this.$store.getters.staffMember["Campus Name"])+" "),(
                      !_vm.isEmpty(this.$store.getters.staffMember['Building Name'])
                    )?_c('span',[_vm._v(" - "+_vm._s(this.$store.getters.staffMember["Building Name"])+" ")]):_vm._e()])])],1):_vm._e()],1),_c('br'),_c('v-divider',{staticClass:"mx-4"}),_c('br'),_c('div',{staticClass:"contact-inline"},[(!_vm.isEmpty(_vm.$store.getters.staffMember['Room Number']))?_c('p',{class:{ 'body-2': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-icon',{attrs:{"color":"#903"}},[_vm._v("mdi-map-marker")]),_vm._v("Room: "+_vm._s(_vm.$store.getters.staffMember["Room Number"])+" ")],1):_vm._e(),_c('br'),(
                !_vm.isEmpty(_vm.$store.getters.staffMember['Email']) &&
                _vm.$store.getters.staffMember['Email'].match(/n\/a/g) == null
              )?_c('p',{class:{ 'body-2': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-icon',{attrs:{"color":"#903"}},[_vm._v("mdi-at")]),(!_vm.hide)?_c('a',{attrs:{"href":`mailto:${_vm.$store.getters.staffMember['Email']}`}},[_vm._v(_vm._s(_vm.$store.getters.staffMember["Email"]))]):_vm._e(),(_vm.hide)?_c('span',{staticClass:"ext"},[_vm._v(_vm._s(_vm.$store.getters.staffMember["Email"]))]):_vm._e()],1):_vm._e()]),(!_vm.isEmpty(_vm.phone))?_c('div',{class:{
              'contact-inline': true,
              'body-2': _vm.$vuetify.breakpoint.smAndDown,
            }},_vm._l((_vm.phone.split(', ')),function(ext){return _c('a',{key:ext,staticClass:"phone tl",attrs:{"text":"","color":_vm.tab == ext ? '#903' : '',"href":`tel:${ext}`},on:{"click":function($event){_vm.tab = ext}}},[_c('v-icon',{attrs:{"left":"","color":"#903"}},[_vm._v("mdi-phone")]),_vm._v(_vm._s(ext)+" ")],1)}),0):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"qr"},[(!_vm.isEmpty(_vm.phone))?_c('v-window',{staticClass:"elevation-0",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.phone.split(', ')),function(ext){return _c('v-window-item',{key:ext,attrs:{"value":`${ext}`}},[(!_vm.loading && !_vm.isEmpty(ext) && !isNaN(_vm.stripExt(ext)))?_c('qr',{staticClass:"qr-code",attrs:{"size":100,"text":`tel:${_vm.stripExt(ext)}`}}):_vm._e()],1)}),1):_vm._e(),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":32,"width":4,"color":"light-blue"}}):_vm._e(),(!_vm.loading && _vm.checkPhone())?_c('p',[_vm._v(" Scan the QR code above to call "+_vm._s(_vm.staffName())+" on "),_c('span',{staticClass:"ext"},[_vm._v(_vm._s(_vm.getExt(_vm.tab)))])]):_vm._e(),(!_vm.loading && _vm.isEmpty(_vm.phone))?_c('p',{staticClass:"no-qr"},[_vm._v(" Sorry there was no number to create a QR-Code. ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"contact-inline"},[(!_vm.isEmpty(_vm.$store.getters.staffMember['lang']) && !_vm.hide)?_c('p',{class:{ 'body-2': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-icon',{attrs:{"color":"#903"}},[_vm._v("mdi-translate")]),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":32,"width":4,"color":"light-blue"}}):_vm._e(),(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.showLang()))]):_vm._e()],1):_vm._e()])],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }