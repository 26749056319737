<style scoped>
.group h3 {
  margin: 0.1rem;
  padding: 0.125rem 0.5em;
  /* border-style: solid none double none; */
  /* border-top-width:1px ;
  border-color: #FF5C5E; */
  border-radius: 0.2rem;
  background-color: #903;
  color: #FFF;
  /* width: 100%; */
}

.group h4 {
  padding-left: 1rem;
  border-width: 0px;
  border-bottom: 1px;
  border-style: solid;
  border-color: #FF5C5E;
  color: #903;
  font-size: 0.9rem;
  width: 98%;
  margin-left: 1%;
}

.group .staff-card {
  margin-bottom: 0.5em;
  width: 98%;
  margin-left: 1%;
}

.staff-list-header {
  border-bottom: 0.5em solid #903;
}

.staff-list-area {
  height: 70vh;
  overflow-y: auto;
  padding-bottom: 2em;
}

@media (max-width: 900px) and (max-height: 1200px) {
  .staff-list-display {
    display: none !important;
  }
}
</style>

<template>
  <v-container fluid>
    <h2 color="#903" class="staff-list-header headline">{{ heading }}</h2>

    <v-row align="stretch">
      <v-col cols="12" md="6" v-if="list.length > 0 || search != ''">
        <v-row align="center" dense no-guters>
          <v-col cols="12">
            <v-text-field
              label="Filter Staff List Below"
              dense
              color="#903"
              hint="Use this field to filter your search"
              clearable
              v-model="search"
            >
              <template v-slot:append>
                <v-btn  icon color="#903">
                  <v-icon color="#903">mdi-filter</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense no-guters v-if="!$store.getters.loading">
          <!-- If its a School/Division -->
          <v-row
            class="group staff-list-area"
            v-if="list.length > 0 && Object.keys(list[0])[0] == 'groupName'"
          >
            <v-col v-for="(group, index) in list" :key="index" cols="12">
              <!--ACADEMIC GROUP  -->
              <template v-if="group['groupType'] == 'academic_unit'"> 
                <h4
                  v-if="!isEmpty(group['groupName']) && group['staffList'].length > 0"
                >
                  <v-icon color="#903">
                    mdi-human-male-board  
                  </v-icon>
                  {{ group["groupName"] }} 
                </h4>
              </template>
              <!-- DEPARTMENT GROUP -->
              <template v-else-if="group['groupType'] == 'department'">
                <h3
                  v-if="!isEmpty(group['groupName']) && group['staffList'].length > 0"
                  class=""
                >
                  <v-icon color="#fff" >
                    mdi-account-group
                  </v-icon>
                  {{ group["groupName"] }}
                </h3>
              </template>
              <!-- TEAM GROUP -->
              <template v-else-if="group['groupType'] == 'team'">
                <h4
                  v-if="!isEmpty(group['groupName']) && group['staffList'].length > 0"
                >
                  <v-icon color="#903">
                    mdi-account-multiple
                  </v-icon>
                  {{ group["groupName"] }}
                </h4>
              </template>
               

              <StaffCard
                v-for="(staff, j) in group['staffList']"
                class="staff-card my-2"
                :staff="staff"
                :key="`${index}-${j}`"
                :selected="selected == staff"
                :showDetails="showDetails"
                @selected="viewStaff"
              >
              </StaffCard>
            </v-col>

            <v-col cols="12" class="pt-0">
              <v-btn
                v-show="!hideBack"
                class="my-2"
                color="#903"
                width="100%"
                dark
                @click="goBack()"
              >
                <v-icon left color="white">mdi-arrow-left</v-icon>
                back
              </v-btn>
            </v-col>
          </v-row>

          <!-- If its staff search -->
          <v-row
            dense
            class="group staff-list-area"
            v-if="list.length > 0 && Object.keys(list[0])[0] != 'groupName'"
          >
            <v-col cols="12">
              <StaffCard
                v-for="(staff, i) in list"
                class="staff-card my-2"
                :staff="staff"
                :key="i"
                :selected="selected == staff"
                :showDetails="showDetails"
                @selected="viewStaff"
              ></StaffCard>
            </v-col>

            <v-col cols="12" class="pt-0">
              <v-btn
                v-show="!hideBack"
                class="my-2"
                color="#903"
                width="100%"
                dark
                @click="goBack()"
              >
                <v-icon left color="white">mdi-arrow-left</v-icon>
                back
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row v-else align="center" justify="center">
          <v-col cols="4">
            <v-progress-circular
              v-if="$store.getters.loading"
              indeterminate
              :size="96"
              :width="6"
              color="light-blue"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="staff-list-area"
        v-if="list.length == 0 && search == ''"
      >
        <v-row justify="center" align="center">
          <v-progress-circular
            v-if="$store.getters.loading"
            indeterminate
            :size="96"
            :width="6"
            color="light-blue"
          ></v-progress-circular>

          <div v-if="!$store.getters.loading">
            <p>No results were found</p>
          </div>
        </v-row>
      </v-col>

      <v-col cols="0" md="6" class="staff-list-display">
        <!-- Staff Profile -->
        <Profile
          :staff="selected"
          @close="close"
          @interact="setTimer()"
        ></Profile>
      </v-col>
    </v-row>

    <v-dialog v-model="openModal" max-width="800px" height="80vh">
    <!-- Staff Profile -->
    <Profile class="dialog-view"
		      	:staff="selected"
		      	@close="close"
		      	@interact="setTimer()"></Profile>
    </v-dialog>
  </v-container>
</template>

<script>
import { isEmpty, escapeRegExp, debugMsg } from "../../lib/helpers";
//import {getSchoolStaff} from '../../lib/api';
import {
			getPrivateStaffDetails,
			getPremiseStaffDetails,
		} from "../../lib/private-api";

import Profile from "../Profile";
import StaffCard from "../StaffCard";

export default {
  name: "staff-list",
  props: ["heading", "staffList"],
  components: {
    Profile,
    StaffCard,
  },

  data: () => ({
    selected: {},
    search: "",
    hideBack: false,
    resetTime: 6e4, // time until selected staff resets
    timeoutId: "",
    dialog: false,

    filterOptions: [],
    filterOption: "",
  }),

  mounted() {
    // Hide the back button and reduce reset time on secure pages
    if (this.$route.path.match(/secure/g) != null) {
      this.hideBack = true;
      this.resetTime = 3e4; // set reset to 30 seconds
    } else {
      this.hideBack = false;
      this.resetTime = 6e4; // set reset to 60 seconds
    }
  },

  beforeUpdate() {
    // Hide the back button and reduce reset time on secure pages
    if (this.$route.path.match(/secure/g) != null) {
      this.hideBack = true;
      this.resetTime = 3e4; // set reset timer to 30 seconds
    } else {
      this.hideBack = false;
      this.resetTime = 6e4; // set reset timer to 60 seconds
    }
  },

  watch: {
    staffList: function (newVal, oldVal) {
      // Auto select result list of one staff member
      if (newVal != oldVal) {
        if (
          newVal.length == 1 &&
          !newVal[0].isUnit &&
          newVal[0].groupName == undefined
        ) {
          this.selected = this.staffList[0];
          this.$store.commit("updateStaffMember", this.staffList[0]);
          this.openModal = true;
        }
      }
	},

  },

  methods: {
    close: function () {
      this.selected = {};
      this.openModal = false;
    },

    /**
     * Navigate to the previous page & reset any groups
     */
    goBack: function () {
      if (this.$store.getters.back === true) {
        // reset staff groups from previous page
        this.$store.commit("updateStaff", {
          data: { data: this.$store.getters.temp },
        });
        this.$store.commit("updateTemp", []);
        this.$store.commit("updateBack", false);
      }

      this.$router.go(-1);
    },

    /**
     * Local wrapper for helper function to see if a string | array is empty
     *
     * @param test {String|Array}: the variable to test if empty
     * @return {Boolean}
     */
    isEmpty: function (test) {
      return isEmpty(test);
    },

    /**
     * Select a staff member to display their profile
     *
     * @param staff {Object}: The staff member to display OR The Org Unit to get staff from
     */
    viewStaff: function (staff) {
      if (staff["ID"] == undefined) {
        // If the selected object is an org unit, the DB is queried
        // for a list of applicable staff
        this.search = "";
        this.$emit('selected', staff);
      } else {
        // Display the selected staff member
		this.openModal = true
		// this.requestDetails();
        this.selected = staff;
		// this.$store.commit("updateStaffMember", staff);
		// debugMsg("GETTER "+this.$store.getters.staffMember["ID"])
        this.setTimer()
      }
    },

    requestDetails: function () {
      this.loading = true;

      if (this.$store.getters.isLoggedIn) {
        // get valid token then request details
        this.$authService.getToken().then(
          (accessToken) => {
            // Request details
            getPrivateStaffDetails(accessToken, this.selected.ID)
              .then((res) => {
                this.successfulStaffDetails(res);
              })
              .catch((err) => {
				  debugMsg("error1 "+err)
                this.errorStaffDetails(err);
              });
          },
          (error) => {
            this.$store.commit(
              "errorMsg",
              `There was an error retrieving results`
            );
          }
        );
      } else {
        // Request details

        if (this.$route.path.match(/secure/gi) !== null) {
          getPremiseStaffDetails(this.selected.ID)
            .then((res) => {
              this.successfulStaffDetails(res);
            })
            .catch((err) => {
              this.errorStaffDetails(err);
            });
        } else {
          getStaffDetails(this.selected.ID)
            .then((res) => {
              this.successfulStaffDetails(res);
            })
            .catch((err) => {
              this.errorStaffDetails(err);
            });
        }
      }
    },

    successfulStaffDetails: function (res) {

      this.loading = false;
      this.showLogin = !this.checkPermision();
      // Check if API returned any results
      if (res.data.data.length == 0) {
        this.$store.commit("errorMsg", "The staff details could not be found");
      } else {
        // store result in vuex
        this.$store.commit("updateStaffMember", res.data.data);
      }
    },

    errorStaffDetails: function (err) {
      // display error message
      this.loading = false;
      this.showLogin = true;
      this.$store.commit("errorMsg", `There was an error retrieving results`);

      this.errorLogin(err);
	},
	
	    /**
     * Check if the staff profile should be displayed
     */
    checkPermision: function () {
      if (
        this.$route.path.match(/secure/gi) !== null &&
        Object.keys(this.$store.getters.staffMember).length > 8
      ) {
        return true;
      }

      if (Object.keys(this.$store.getters.staffMember).length > 8) {
        let copy = Object.assign({}, this.$store.getters.staffMember);
        let show = false;

        /* Check if there are more attributes available than those 
					 displayed on the card*/
        Object.keys(copy).forEach((k) => {
          switch (k) {
            case "ID":
            case "Title":
            case "First Names":
            case "Surname":
            case "JPFlag":
            case "StaffIDNumber":
            case "Sort Position":
            case "Position":
            case "Campus Name":
              break;

            default:
              if (copy[k] != null) {
                show = true;
              }
              break;
          }
        });

        return show;
      }
      return false;
    },

    /**
     * Set a timer to reset the selected staff member & search params
     * after a given period of inactivity
     */
    setTimer: function () {
      if (this.timeoutId != "") {
        // reset timout
        clearTimeout(this.timeoutId);
      }

      var vm = this;
      this.timeoutId = setTimeout(() => {
        vm.reset();
      }, this.resetTime);
    },

    /**
     * Reset the seleceted staff & search query to empty.
     */
    reset: function () {
    //   console.log("In timer reset");
      this.selected = {};
      this.$store.commit("updateStaffMember", {});
      this.search = "";
      this.openModal = false;

      // TODO add a scroll reset here

      debugMsg("... Staff list & selection reset complete!");
    },

    /**
     * Search staff member fields to find any matches to the search query
     * @param staff {Object}: The staff member to be checked
     * @return {Boolean}
     */
    filterStaff: function (staff) {
      var match = false;
      var vm = this;
      var re = new RegExp(escapeRegExp(vm.search), "i");
      var toSearch = ["Room Number", "Position", "Email", "Extension"];

      // Check if search matches the name or any other field
      if (
        String(
          `${staff["Title"]} ${staff["First Names"]} ${staff["Surname"]}`
        ).match(re) != null
      ) {
        match = true;
      } else {
        toSearch.forEach((key) => {
          if (String(staff[key]).match(re) != null) {
            match = true;
          }
        });
      }

      return match;
    },

    /**
     * Takes a list of staff members OR staff groups and sorts the
     * members based on the pecking order specified
     *
     * @param list {Array}: An array of staff objects OR an array of
     * 	staff groups
     * @return {Array}: the sorted staff list
     */
    sortList: function (list) {
      var vm = this;

      // if the list is staff groups sort each sub list
      if (list[0] != undefined && list[0].groupName != undefined) {
        // console.log("In group sort");
        return list.filter((group) => {
          group["staffList"] = group["staffList"].sort(vm.sortStaff);

          return true;
        });
      } else {
        // console.log("In staff list sort");
        return list.sort(vm.sortStaff);
      }
    },

    /**
     * Sort staff members by the pecking order specified in the DB
     * @param a {Object}: A Staff member to compare
     * @param b {Object}: A Staff member to compare
     */
    sortStaff: function (a, b) {
      // Ensure pecking code exists

      if (a["Sort Position"] == null) {
        return 1;
      }
      if (a["Sort Position"] > b["Sort Position"]) {
        return 1;
      }
      if (a["Sort Position"] < b["Sort Position"]) {
        return -1;
      }
      return 0;
    },
  },

  computed: {
    openModal: {
      get() {
        return this.dialog && this.$vuetify.breakpoint.smAndDown;
      },

      set(val) {
        this.dialog = val;
      },
    },

    showDetails() {
      return this.$route.path.match(/.*building.*/) != null;
    },

    /**
     * Filter the list of staff members displayed based on the search query
     * @return {Array}: the applicable staff list or staff group
     */
    list() {
      // If no search term
      if (this.search == "") {
        if (this.staffList == null || this.staffList == undefined) {
          return [{}];
        } else {
          if (
            this.staffList.length > 0 &&
            Object.keys(this.staffList[0])[0] == "groupName"
          ) {
            this.staffList.forEach((staff) => {
              if (staff.hasOwnProperty("tempList")) {
                staff["staffList"] = staff["tempList"];
              }
            });
          }

        //   console.log("Sort Staff");
          return this.sortList(this.staffList);
        }
      } else {
        var vm = this;
        var re = new RegExp(escapeRegExp(vm.search), "ig");

        this.setTimer();

        // filter list based on search term, then sort the members
        return vm.sortList(
          vm.staffList.filter((staff) => {
            var match = false;

            debugMsg("Filtering List");

            if (Object.keys(staff)[0] == "groupName") {
              if (!staff.hasOwnProperty("tempList")) {
                staff["tempList"] = staff["staffList"];
              }

              staff["staffList"] = staff["tempList"];

              debugMsg("Checking Group Name");

              if (String(staff["groupName"]).match(re) != null) {
                debugMsg(`Group Name Matched ${staff["groupName"]}`);
                match = true;
              } else {
                debugMsg("Checking Staff");

                staff["staffList"] = staff["staffList"].filter((st) => {
                  if (vm.filterStaff(st)) {
                    debugMsg("Match Found");
                    match = true;
                    return true;
                  }

                  return false;
                });
              }
            } else {
              match = vm.filterStaff(staff);
            }

            debugMsg(`Returning Match: ${match}`);

            return match;
          })
        );
      }
    },
  },
};
</script>