<style scoped>
/* -- BRAND -- */
.brand {
  background: #ff5c5e;
  /*flex-grow: 1;

		display: flex;
		flex-flow: column;
		height: 100%;
		justify-content: center;
		position: relative;
		*/
}

.brand > * {
  align-self: center;
  margin: auto;
}

.card-title {
  border-top: 2px solid #903;
  text-transform: uppercase;
  color: #903;
}

.warningLogin {
  position: absolute;
  bottom: 100px;
  background-color: #903;
  padding: 1em;
  border: none;
  border-radius: 0.25em;
  color: #fff;
}
/* -- END OF BRAND --*/

/* -- Profile -- */
.profile-container {
  position: relative;
  height: auto;
  background-color: #fff;
  height: 100%;
  width: 100%;
  /*overflow: hidden;*/
}

i {
  margin-right: 0.25em;
  margin-left: 0.5em;
  vertical-align: top;
  color: #903;
}

/* -- Action Area -- */
.closeModal,
.actions {
  position: absolute;
  top: 0.5em;
}

.actions {
  right: 0.5em;
}

.closeModal {
  left: 0.5em;
}

.closeModal i,
.actions i {
  margin: 0px 0.125em !important;
}

/* -- PROFILE -- */
.profile {
  box-sizing: border-box;
  /*height: 100%;*/
  margin-bottom: 1em;

  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "contact"
    "qr"
    "additional";

  justify-content: center;
  align-content: stretch;
}

/* -- QR -- */
.qr {
  grid-area: qr;
  text-align: center;
  padding: 0.75rem;
}

.qr p {
  font-size: 0.75em;
  margin-bottom: 0.25em;
}

.no-qr {
  margin: 60px;
}

.qr-code {
  display: inline-block;
  margin: 0.75rem;
}
/* -- END OF QR --*/

/* -- Important Contact -- */
.important-contact {
  margin-top: 3em;
  grid-area: contact;
  text-align: center;
  align-self: center;
}

.important-contact h2 {
  font-size: 1.5rem;
  margin: 0px;
}

.contact-inline * {
  display: inline;
}

/* Additional Info */
.infoSection {
  grid-area: additional;
  width: 100%;
  align-self: stretch;
  /*overflow: hidden;
		overflow-y: scroll;*/
  align-content: center;
}

.loader {
  display: inline-block;
}

.ext {
  color: #ff0033;
  font-size: 12px;
}
a {
  color: #ff0033 !important;
  text-decoration: none;
}
</style>
<template>
  <v-container fill-height fluid class="pa-0">
    <!-- Logo -->
    <v-container v-if="!checkPermision()" fill-height fluid class="brand">
      <v-row justify="center">
        <img :src="`${publicPath}/img/WSU_Shield_White.svg`" width="400" />
      </v-row>

      <v-row justify="center">
        <v-col cols="12" class="text-center">
            <!-- v-if="showLogin && !loading" -->
          <v-btn
            type="button"
            @click="loginGetUser"
            color="#903"
            x-large
            :class="{
              'white--text': true,
              'body-2': $vuetify.breakpoint.smAndDown,
            }"
            v-if="
            !$store.getters.isLoggedIn &&
            Object.keys($store.getters.staffMember).length > 8 &&
            !$store.getters.onPremise
          "
            >
            Login to view staff details
          </v-btn>

          <v-progress-circular
            v-if="loading"
            indeterminate
            :size="48"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <!-- Profile Details -->
    <div v-if="checkPermision()" class="profile-container">
      <!-- Close Action Buttons -->
      <div class="closeModal">
        <v-btn icon @click="close" color="#903">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <!-- ACTIONS COPY, PROFILE -->
      <div class="actions" v-if="!hide">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              @click="
                copy(
                  `https://directory.westernsydney.edu.au/search/profile/${$store.getters.staffMember['ID']}`
                )
              "
              color="#903"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy Permalink</span>
        </v-tooltip>

        <v-tooltip
          v-if="
            $store.getters.isLoggedIn &&
            Object.keys($store.getters.staffMember).length > 8 &&
            !$store.getters.onPremise
          "
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :text="$vuetify.breakpoint.mdAndUp"
              :icon="$vuetify.breakpoint.smAndDown"
              @click="loginGetUser"
              color="#903"
            >
              <v-icon :left="$vuetify.breakpoint.mdAndUp"
                >mdi-dots-horizontal</v-icon
              >
              {{ $vuetify.breakpoint.mdAndUp ? "More Details" : "" }}
            </v-btn>
          </template>
          <span>Login to view staff details</span>
        </v-tooltip>
        <!-- Staff Profile Link -->
        <v-btn
          v-if="!isEmpty(getProfile())"
          :text="!$vuetify.breakpoint.smAndDown"
          :icon="$vuetify.breakpoint.smAndDown"
          color="#903"
          :href="`${getProfile()}`"
          target="_blank"
        >
          <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-face</v-icon>
          <span v-if="!$vuetify.breakpoint.smAndDown">View Profile</span>
        </v-btn>
      </div>

      <div class="profile">
        <!-- Important Information -->
        <div class="important-contact">
          <!-- NAME -->
          <h1 class="card-title">{{ staffName() }}</h1>

          <h4>{{ this.$store.getters.staffMember["Position"] }}</h4>
          <br />
          <v-divider class="mx-4"></v-divider>
          <br />

          <v-container
            :class="{ 'mx-auto': true, 'pa-0': $vuetify.breakpoint.smAndDown }"
          >
            <v-row
              v-if="this.$store.getters.staffMember['School Unit Name']"
              dense
              no-gutters
              class="text-center mb-1"
            >
              <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

              <v-col
                cols="12"
                sm="4"
                :class="{
                  'mr-24': true,
                  'text-right': $vuetify.breakpoint.smAndUp,
                  'body-2': $vuetify.breakpoint.smAndDown,
                }"
              >
                <strong>Department:</strong>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                :class="{ 'body-2': $vuetify.breakpoint.smAndDown }"
              >
                <a
                  v-if="!hide"
                  :href="`/schools/${this.$store.getters.staffMember['School Unit Code']}/staff`"
                  target="_blank"
                >
                  {{ this.$store.getters.staffMember["School Unit Name"] }}
                  <v-icon color="#903" x-small> mdi-open-in-new </v-icon>
                </a>
              </v-col>
            </v-row>
            <v-row
              v-if="this.$store.getters.staffMember['Unit Name']"
              dense
              no-gutters
              class="text-center mb-1"
            >
              <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

              <v-col
                cols="12"
                sm="4"
                :class="{
                  'mr-24': true,
                  'text-right': $vuetify.breakpoint.smAndUp,
                  'body-2': $vuetify.breakpoint.smAndDown,
                }"
              >
                <strong>School or Division:</strong>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                :class="{ 'body-2': $vuetify.breakpoint.smAndDown }"
              >
                <a
                  v-if="!loading && !hide"
                  :href="`/schools/${this.$store.getters.staffMember['Department Code']}/staff`"
                  target="_blank"
                >
                  {{ this.$store.getters.staffMember["Unit Name"] }}
                  <v-icon color="#903" x-small> mdi-open-in-new </v-icon>
                </a>
              </v-col>
            </v-row>
            <v-row
              v-if="this.$store.getters.staffMember['Campus Name']"
              dense
              no-gutters
              class="text-center mb-1"
            >
              <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

              <v-col
                cols="12"
                sm="4"
                :class="{
                  'mr-24': true,
                  'text-right': $vuetify.breakpoint.smAndUp,
                  'body-2': $vuetify.breakpoint.smAndDown,
                }"
              >
                <strong>Campus:</strong>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                :class="{ 'body-2': $vuetify.breakpoint.smAndDown }"
              >
                <h4>
                  {{ this.$store.getters.staffMember["Campus Name"] }}
                  <span
                    v-if="
                      !isEmpty(this.$store.getters.staffMember['Building Name'])
                    "
                  >
                    - {{ this.$store.getters.staffMember["Building Name"] }}
                  </span>
                </h4>
              </v-col>
            </v-row>
          </v-container>

          <br />
          <v-divider class="mx-4"></v-divider>
          <br />
          <!-- EMAIL -->
          <div class="contact-inline">
            <p
              v-if="!isEmpty($store.getters.staffMember['Room Number'])"
              :class="{ 'body-2': $vuetify.breakpoint.smAndDown }"
            >
              <v-icon color="#903">mdi-map-marker</v-icon>Room:
              {{ $store.getters.staffMember["Room Number"] }}
            </p>
            <br />
            <p
              v-if="
                !isEmpty($store.getters.staffMember['Email']) &&
                $store.getters.staffMember['Email'].match(/n\/a/g) == null
              "
              :class="{ 'body-2': $vuetify.breakpoint.smAndDown }"
            >
              <v-icon color="#903">mdi-at</v-icon>
              <a
                v-if="!hide"
                :href="`mailto:${$store.getters.staffMember['Email']}`"
                >{{ $store.getters.staffMember["Email"] }}</a
              >
              <span v-if="hide" class="ext">{{
                $store.getters.staffMember["Email"]
              }}</span>
            </p>
          </div>
          <!-- EXTENSION AND MOBILE -->
          <div
            :class="{
              'contact-inline': true,
              'body-2': $vuetify.breakpoint.smAndDown,
            }"
            v-if="!isEmpty(phone)"
          >
            <a
              v-for="ext in phone.split(', ')"
              :key="ext"
              class="phone tl"
              text
              :color="tab == ext ? '#903' : ''"
              @click="tab = ext"
              :href="`tel:${ext}`"
            >
              <v-icon left color="#903">mdi-phone</v-icon>{{ ext }}
            </a>
          </div>

          <!-- QR Code -->
          <div class="qr" v-if="$vuetify.breakpoint.smAndUp">
            <v-window v-model="tab" class="elevation-0" v-if="!isEmpty(phone)">
              <v-window-item
                v-for="ext in phone.split(', ')"
                :key="ext"
                :value="`${ext}`"
              >

                <qr
                  v-if="!loading && !isEmpty(ext) && !isNaN(stripExt(ext))"
                  class="qr-code"
                  :size="100"
                  :text="`tel:${stripExt(ext)}`"
                ></qr>
              </v-window-item>
            </v-window>

            <v-progress-circular
              v-if="loading"
              indeterminate
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>

            <p v-if="!loading && checkPhone()">
              Scan the QR code above to call {{ staffName() }} on
              <span class="ext">{{ getExt(tab) }}</span>
            </p>

            <p
              class="no-qr"
              v-if="!loading && isEmpty(phone)"
            >
              Sorry there was no number to create a QR-Code.
            </p>
          </div>

          <div class="contact-inline">
            <p
              v-if="!isEmpty($store.getters.staffMember['lang']) && !hide"
              :class="{ 'body-2': $vuetify.breakpoint.smAndDown }"
            >
              <v-icon color="#903">mdi-translate</v-icon>

              <v-progress-circular
                v-if="loading"
                indeterminate
                :size="32"
                :width="4"
                color="light-blue"
              ></v-progress-circular>

              <span v-if="!loading">{{ showLang() }}</span>
            </p>
          </div>
        </div>

        <!-- {{ $store.getters.staffMember }} -->
        <!-- Slide of Additional Information -->
        <!-- <SlideShow class="infoSection" :staff="$store.getters.staffMember" :loading="loading"
				@click="$emit('interact')"></SlideShow> -->
      </div>
    </div>
  </v-container>
</template>

<script>
import {
  isEmpty,
  getExtension,
  getTitle,
  validateNumber,
  showLang,
  debugMsg,
} from "../lib/helpers";
import {
  getPrivateStaffDetails,
  getPremiseStaffDetails,
} from "../lib/private-api";
import { getStaffDetails } from "../lib/api";
import qr from "vue-qrcode-component";
// import SlideShow from "./SlideShow";

import clipboard from "../lib/copy-clipboard.mixin.js";

export default {
  name: "profile",
  props: ["heading", "staff"],

  components: {
    qr,
    // SlideShow,
  },

  mixins: [clipboard],

  data: () => ({
    hide: false,
    showLogin: true,
    tab: null,
    loading: false,
    publicPath: process.env.VUE_APP_BASE_URL,
  }),

  computed: {
    phone() {
      let ph = this.$store.getters.staffMember["Phones"];
      let mob = this.$store.getters.staffMember["Mobile Telephone"]; 
      let fax = this.$store.getters.staffMember["Personal Facsimile"]; //This will accomodate telephone numbers from 2024, FAX is obsolete at the university.

      if (Array.isArray(ph)) {

        // check if mobile exists
        let index = ph.indexOf(mob);

        if ((index = -1 && mob != null)) {
          ph = ph.append(mob);

        }

        // check if fax/tel exists
        let indexFAX = ph.indexOf(fax);

        if ((indexFAX = -1 && fax != null)) {
          ph = ph.append(fax);

        }

      } 
      else if (typeof ph == "string") 
      {
        
        if (mob != null && ph.match(mob) == null) {
          ph = `${ph}, ${this.$store.getters.staffMember["Mobile Telephone"]}`;

        }
        if (fax != null && ph.match(fax) == null) {
          ph = `${ph}, ${this.$store.getters.staffMember["Personal Facsimile"]}`;
          // console.log(ph)
        }
      }
          // console.log(fax)
      if (ph == null && mob != null) {
        ph = `${this.$store.getters.staffMember["Mobile Telephone"]}`;

      }
      if (ph == null && fax != null) {
        ph = `${this.$store.getters.staffMember["Personal Facsimile"]}`;

      }

      if (!isEmpty(ph) && validateNumber(ph)) {
        // console.log(ph)
        return ph;
      }

      return "";
    },
    getCampus: function () {
      var camp = this.$store.getters.findCampus(this.staff["Campus Code"]);

      if (camp) {
        return camp["Campus Name"];
      }

      return "";
    },

    getDept: function () {
      // console.log("DEPT")
      // console.log(this.$store.getters.staffMember["Department Code"])

      var dept = this.$store.getters.findUnit(
        this.$store.getters.staffMember["Department Code"]
      );

      if (dept) {
        return dept["Unit Name"];
      }
      return "";
    },

    getSchool: function () {
      var school = this.$store.getters.findParentUnit(
        this.$store.getters.staffMember["Department Code"]
      );

      if (school) {
        return school["Unit Name"];
      }
      return "";
    },

    getBuilding: function () {
      if (
        !isEmpty(this.$store.getters.staffMember["Room Number"]) &&
        !isEmpty(this.$store.getters.staffMember["Campus Code"])
      ) {
        var building = this.$store.getters.findCampusBuilding(
          this.$store.getters.staffMember["Campus Code"],
          this.$store.getters.staffMember["Room Number"].split(".")[0]
        );

        if (building) {
          return building["Building Name"];
        }
      }

      return "";
    },
  },

  mounted() {
    if (this.$route.path.match(/secure/g) != null) {
      this.hide = true;
    } else {
      this.hide = false;
    }
  },

  watch: {
    staff: function (newVal, oldVal) {
      if (
        !this.$store.getters.isLoggedIn &&
        !this.$store.getters.onPremise &&
        this.$route.path.match(/secure/gi) == null
      ) {
        this.showLogin = true;
      }

      if (!isEmpty(newVal) && Object.keys(newVal).length > 0) {
        if (newVal["ID"] != oldVal["ID"]) {
          return this.requestDetails();
        }
      }
    },

    $route(to) {
      if (to.path.match(/secure/g) != null) {
        this.hide = true;
      } else {
        this.hide = false;
      }
    },
  },

  created() {
    this.$watch(() => this.$store.getters.staffMember["Phones"], this.setTab);
  },

  methods: {
    copy: function (text) {
      this.copyToClipboard(text);
      this.$store.commit("successMsg", "Copied record link to your clipboard");
    },

    getExt: function (phone) {
      if (
        "Extension" in this.$store.getters.staffMember &&
        "Phones" in this.$store.getters.staffMember &&
        this.$store.getters.staffMember["Extension"] != null &&
        this.$store.getters.staffMember["Phones"] != null
      ) {
        let ext = this.$store.getters.staffMember["Extension"].split(", ");
        let ph = this.$store.getters.staffMember["Phones"].split(", ");
        // console.log(ph)
        return ext[ph.indexOf(phone)];
      }
    },

    stripExt: function (ext) {
      return ext.replace(/(\(|\)|\s)/gi, "");
    },

    checkPhone: function () {
      return (
        !isEmpty(this.$store.getters.staffMember["Phones"]) &&
        !isNaN(
          this.$store.getters.staffMember["Phones"].replace(
            /(\(|\)|\s|\,)/gi,
            ""
          )
        )
      );
    },

    setTab: function (val) {
      if (val == undefined || val == null || val == "") {
        this.tab = "";
      } else {
        this.tab = val.split(", ")[0];
      }
    },

    close: function () {
      this.$store.commit("updateStaffMember", {});
      this.showLogin = false;
      this.$emit("close");
    },

    loginGetUser: function () {
      this.login().then((res) => {
        if (res) {
          this.requestDetails();
        }
      });
    },

    /**
     * Check if the staff profile should be displayed
     */
    checkPermision: function () {
      if (
        this.$route.path.match(/secure/gi) !== null &&
        Object.keys(this.$store.getters.staffMember).length > 8
      ) {
        return true;
      }

      if (Object.keys(this.$store.getters.staffMember).length > 8) {
        let copy = Object.assign({}, this.$store.getters.staffMember);
        let show = false;

        /* Check if there are more attributes available than those 
					 displayed on the card*/
        Object.keys(copy).forEach((k) => {
          switch (k) {
            case "ID":
            case "Title":
            case "First Names":
            case "Surname":
            case "JPFlag":
            case "StaffIDNumber":
            case "Sort Position":
            case "Position":
            case "Campus Name":
              break;

            default:
              if (copy[k] != null) {
                show = true;
              }
              break;
          }
        });

        return show;
      }
      return false;
    },

    loginRefresh() {
      this.loginFailed = false;
      this.login();
    },

    /**
     * Return a staff members second languages
     */
    showLang: function () {
      return showLang(this.$store.getters.staffMember);
    },

    staffName: function () {
      return getTitle(this.$store.getters.staffMember);
    },

    requestDetails: function () {
      //if (this.checkPermision()) {
      this.loading = true;

      if (this.$store.getters.isLoggedIn) {
        // get valid token then request details
        this.$authService.getToken().then(
          (accessToken) => {
            // Request details
            getPrivateStaffDetails(accessToken, this.staff.ID)
              .then((res) => {
                this.successfulStaffDetails(res);
              })
              .catch((err) => {
                this.errorStaffDetails(err);
              });
          },
          (error) => {
            this.$store.commit(
              "errorMsg",
              `There was an error retrieving results`
            );
          }
        );
      } else {
        // Request details

        if (this.$route.path.match(/secure/gi) !== null) {
          getPremiseStaffDetails(this.staff.ID)
            .then((res) => {
              this.successfulStaffDetails(res);
            })
            .catch((err) => {
              this.errorStaffDetails(err);
            });
        } else {
          getStaffDetails(this.staff.ID)
            .then((res) => {
              this.successfulStaffDetails(res);
            })
            .catch((err) => {
              this.errorStaffDetails(err);
            });
        }
      }
    },

    successfulStaffDetails: function (res) {
      this.loading = false;
      this.showLogin = !this.checkPermision();
      // Check if API returned any results
      if (res.data.data.length == 0) {
        this.$store.commit("errorMsg", "The staff details could not be found");
      } else {
        // store result in vuex
        this.$store.commit("updateStaffMember", res.data.data);
      }
    },

    errorStaffDetails: function (err) {
      // display error message
      this.loading = false;
      this.showLogin = true;
      this.$store.commit("errorMsg", `There was an error retrieving results`);

      this.errorLogin(err);
    },

    isEmpty: function (test) {
      return isEmpty(test);
    },

    getProfile: function () {
      let staff = this.$store.getters.staffMember;

      if (
        !isEmpty(staff["Notes"]) &&
        staff["Notes"].match(/^http/g) != null &&
        staff["Notes"].match(/uws/gi) == null
      ) {
        return staff["Notes"];
      }

      return "";
    },

    /**
     * Return a staff members phone number extensions
     */
    // phoneNumber: function (staff) {
    //   //return staff['Extension'];
    //   if (isEmpty(staff["Extension"])) {
    //     return "";
    //   }

    //   // var ext = getExtension(staff["Extension"]);
    //   var ext = "111000"

    //   if (String(ext).match(/^[a-zA-Z]+/i) == null) {
    //     return ext;
    //   }

    //   return "";
    // },
  },
};
</script>