/*
https://gist.github.com/doot0/85dc9c72c6657c0a27996b05205944d8
*/
export default {
  mounted() {
    this.$on('clipboard-copy', (payload) => {
      this.copyToClipboard(payload)
    })
  },
  methods: {
    /**
     * Copies the given value to the keyboard.
     */
    copyToClipboard(value) {
      try {
        
        // Creates the input if it doesn't exist.
        let ghostExists = (document.querySelector('.app-ghost-clipboard') !== null)
        let ghostInput = !ghostExists ? document.createElement('input') : document.querySelector('.app-ghost-clipboard')
        
        // Style it so it doesn't interfere with layout.
        ghostInput.style.position = 'fixed'
        ghostInput.style.top = 0
        ghostInput.style.left = 0
        ghostInput.style.padding = 0
        ghostInput.style.margin = 0
        ghostInput.style.width = '2em'
        ghostInput.style.height = '2em'
        ghostInput.style.border = 'none'
        ghostInput.style.outline = 'none'
        ghostInput.style.boxShadow = 'none'
        ghostInput.style.background = 'transparent'
        ghostInput.style.color = 'transparent'
        
        // First time setup for ghost input.
        if(!ghostExists) {
          ghostInput.className = 'app-ghost-clipboard'
          ghostInput.setAttribute('type', 'text')
          document.documentElement.appendChild(ghostInput)
        }

         // Assign value to input - select,copy,blur,catch errs
        ghostInput.value = value
        ghostInput.select()

        document.execCommand('copy')
        ghostInput.blur()
      } catch(err) {
        console.error('No copy to clipboard support present.')
        throw err
      }
    }
  }
}