<style scoped>
	.v-card__text body
	{
		padding-bottom: 0px !important;
	}
	.staff-card i {
		margin-right: 0.25em;
		color: #FF5C5E;
	}

	/* --- Selected Staff Cards Styles -- */
	.selected {
		background: #FF5C5E;/*#903;*/
	}

	.selected .name {
		border-bottom-color: #FFF;
		color: #FFF;
		font-size: 1.125em !important;
	}

	.selected i {
		color: #f5f6f5;
	}

	.selected .body  {
		color: #f5f6f5;
	}
	.selected .title  {
		color: #fff;
	}
	/* --- END OF Selected Staff Cards Styles -- */

	.title {
		/* border-bottom: 1px solid #903; */
		font-size: 1.125em !important;
	}
	.name {
		font-size: 1.125em !important;
	}

	.body *:not(div) {
		display: inline;
		font-size: .9em;
	}
	.body {
		padding-bottom: 1px !important;
	}

</style>

<template>
	<v-card 
		elevation="1"
		outlined
		:class="{
			'card': true, 
			'staff-card': true, 
			'selected': selected, 
			'showBody': showDetails
		}" 
		@click="$emit('selected', staff)">

		<div v-if="('DID' in staff) || isEmpty(staff['Campus Name'])">
			<v-card-title 
				primary-title
				class="name py-1"
				dense 
				>
					{{ getTitle(staff) }}
				<v-spacer></v-spacer>
				<v-icon >mdi-chevron-right</v-icon>
			</v-card-title>
		</div>

		<div v-else>
			<v-card-title 
				primary-title 
				class="title py-1" 
				dense
				>
				<!-- Title -->
				{{ getTitle(staff) }}
				<v-spacer></v-spacer>
				<v-icon>mdi-chevron-right</v-icon>
			</v-card-title>

			<v-card-text v-if="$route.path.match(/secure/gi) == null"
				class="body">
				<!-- Position -->
				<p v-if="!isEmpty(staff['Position']) && staff['Position'] != getTitle(staff)" 
					class="my-1 text-no-wrap" >
					<v-icon left small>mdi-clipboard-account</v-icon>
					{{ staff['Position'] }}
				</p>

				<!-- Campus -->
				<p v-if="!isEmpty(staff['Campus Name'])" 
					class="my-1" >
					<v-icon left small>mdi-city</v-icon>
					{{ staff['Campus Name'] }}
				</p>
			</v-card-text>
		</div>
	</v-card>
</template>

<script>
	import {isEmpty, getExtension, getTitle, showLang, debugMsg} from '../lib/helpers';

	export default {
		name: "staff-card",
		props: [
			"staff", 
			"selected",
			"showDetails"
		],

		data: () => ({

		}),

		methods: {

			/**
			* Return a staff members second languages
			*/
			showLang: function() {

				return showLang(this.staff)
			},

			// /**
			// * Return a staff members phone number extensions
			// */
			// phoneNumber: function(staff) {
			// 	//return staff['Extension'];
			// 	if (!isEmpty(staff['Extension'])) {
			// 		var ext = getExtension(staff['Extension']);

			// 		debugMsg(ext)
			// 		if (String(ext).match(/^[a-zA-Z]+/i) == null ) {
			// 			return ext; 
			// 		} 
			// 	}
			// 	return "";
			// },


			/**
			* Return the title for a staff card, either position or name
			*/
			getTitle: function(staff) {
				return getTitle(staff);
			},

			isEmpty: function(test) {
				return isEmpty(test);
			},
		}
	}
</script>